<template>
  <div class="services-container10">
    <section class="services-empathy-vr-section1">
      <div class="services-container11">
        <h2 class="release-text Header1">2024 Product</h2>
        <h2 class="services-heading1 Header1">What is EmpathyVR?</h2>
        <h2 class="services-heading2 CursiveHeader1">
          Empathy Type and Empathy Expression Level Diagnostic Content for Teens
          <span v-html="rawfj8e"></span>
        </h2>
        <span class="services-text15 Content">
          Empathy is a basic human trait that all humans must possess. EmpathyVR is an empathy type detecting
          VR content that allows you to diagnose your empathy type and level of empathy expression through
          the interaction with virtual humans in an immersive environment.
          If you understand your empathy type and level of empathy expression, and strive to understand others and
          their response methods, you can achieve emotional balance and smooth communication in your
          interpersonal relationships.
        </span>
      </div>
      <div class="services-images1">
        <img
          alt="image"
          src="/group%206-600w.png"
          class="services-empathyvrcollectexpression1"
          oncontextmenu="return false"
              style="-webkit-touch-callout:none"
        />
        <div class="services-photo1">
          <div class="services-frame61">
            <img
              alt="Rectangle1221"
              src="/external/page2-01.png"
              class="services-rectangle10"
              oncontextmenu="return false"
              style="-webkit-touch-callout:none"
            />
            <img
              alt="Rectangle1220"
              src="/external/rectangle1220-u1m-400w.png"
              class="services-rectangle11"
              oncontextmenu="return false"
              style="-webkit-touch-callout:none"
            />
          </div>
          <div class="services-frame71">
            <img
              alt="Rectangle1222"
              src="/external/rectangle1222-foaq-400w.png"
              class="services-rectangle12"
              oncontextmenu="return false"
              style="-webkit-touch-callout:none"
            />
            <img
              alt="Rectangle1222"
              src="/external/page2-04.png"
              class="services-rectangle13"
              oncontextmenu="return false"
              style="-webkit-touch-callout:none"
            />
          </div>
        </div>
      </div>
      <div class="services-types">
        <div class="services-container12">
          <img
            alt="Rectangle1224"
            src="/external/rectangle1224-zjp-300h.png"
            class="services-rectangle14"
            oncontextmenu="return false"
              style="-webkit-touch-callout:none"
          />
          <img
            alt="Rectangle1224"
            src="/external/rectangle1224-r0j7-300h.png"
            class="services-rectangle15"
            oncontextmenu="return false"
              style="-webkit-touch-callout:none"
          />
        </div>
        <span class="services-text16">
          <span class="Content">
            <b>Empathy type is represented by the following:</b>
          </span>
          <br class="services-text18" />
          <br class="Content" />
          <span class="Content">
            Cognitive and Reactive: Cognitive (C) - Affective (A)
          </span>
          <br class="Content" />
          <span class="Content">Emotional Type: Positive (P) - Negative (N)</span>
          <br class="Content" />
          <span class="Content">
            Cultural Orientation: Minority (m) or Majority (M)
          </span>
          <br class="services-text25" />
          <br class="Content" />
          <span class="Content">
            <b>Empathy level is measured by:</b><br/><br/>
            Collecting multimodal sensor data such as eye
            tracking, gestures, facial expressions and speech
            processing; to objectively assess the degree of
            'verbal' and 'non-verbal' expressions of empathy. 
          </span>
        </span>
      </div>
    </section>
    <section class="services-empathy-vr-section2">
      <h2 class="services-heading3 SemiHeader1">User results Report</h2>
      <div class="services-container13">
        <div class="services-container14">
          <img
            alt="Rectangle1232"
            src="/external/rectangle1232-xb4-600h.png"
            class="services-rectangle16"
            oncontextmenu="return false"
              style="-webkit-touch-callout:none"
          />
          <img
            alt="Rectangle1234"
            src="/external/rectangle1234-2amn-800w.png"
            class="services-rectangle17"
            oncontextmenu="return false"
              style="-webkit-touch-callout:none"
          />
        </div>
        <div class="services-container15">
          <img
            alt="Rectangle1233"
            src="/external/page3-01.png"
            class="services-rectangle18"
            oncontextmenu="return false"
              style="-webkit-touch-callout:none"
          />
          <img
            alt="Rectangle1231"
            src="/external/page3-02.png"
            class="services-rectangle19"
            oncontextmenu="return false"
              style="-webkit-touch-callout:none"
          />
        </div>
      </div>
      <div class="services-container16">
        <h2 class="services-heading4 SemiHeader2">
          <span class="services-text28">Web based </span>
          <span class="services-text29">monitoring </span>
          <span class="services-text30">system service</span>
        </h2>
        <span class="services-text31 Content">
          EmpathyVR diagnosis and management of diagnosis results are available in a web environment. You
          can also apply for device usage training and psychological counseling. Additionally, institutions and
          organizations can provide various interpersonal psychological test services other than empathy diagnosis
          with an annual subscription.
        </span>
      </div>
    </section>
    <section class="services-empathy-chair-vr-section1">
      <div class="services-container17">
        <h2 class="release-text-navy Header1">2024 Product</h2>
        <h2 class="services-heading5 Header1">What is SITh?</h2>
        <h2 class="services-heading5-1 Header1">Self-Insight Therapy</h2>
        <h2 class="services-heading6 CursiveHeader1">
          <span class="CursiveHeader1">
            Innovative Immersive Psychotherapy Content for Counseling
          </span>
          <br />
        </h2>
        <span class="services-text34">
          <span class="Content">
            A new form of immersive psychotherapy using Gestalt therapy*, virtual space and virtual avatars.
            You can meet and talk with your future self or your wounded childhood self, implemented with deep
            learning and 3D avatar creation technology, to comfort, encourage and heal your wounds. SITh is an XR
            therapy tool developed through the convergence of psychology and cutting-edge ICT technology. Meet
            family members you miss, friends you want to reconcile with, make your unspoken confessions and find
            psychological relief with XR therapists.
          </span>
          <br />
          <br class="Content" />
          <span class="services-text42 Content">*Gestalt psychology</span>
          <span class="Content">
            is a form of psychotherapy that promotes self-awareness and self-acceptance as the key to
            personal growth.
          </span>
        </span>
      </div>
      <div class="services-images2">
        <img
          alt="image"
          src="/external/png/chair_person.png"
          class="services-empathyvrcollectexpression2"
          oncontextmenu="return false"
              style="-webkit-touch-callout:none"
        />
        <div class="services-photo2">
          <div class="services-frame62">
            <img
              alt="Rectangle1221"
              src="/external/page4-01.png"
              class="services-rectangle20"
              oncontextmenu="return false"
              style="-webkit-touch-callout:none"
            />
            <img
              alt="Rectangle1220"
              src="/external/page4-02.png"
              class="services-rectangle21"
              oncontextmenu="return false"
              style="-webkit-touch-callout:none"
            />
          </div>
          <div class="services-frame72">
            <img
              alt="Rectangle1222"
              src="/external/page4-03.png"
              class="services-rectangle22"
              oncontextmenu="return false"
              style="-webkit-touch-callout:none"
            />
            <img
              alt="Rectangle1222"
              src="/external/page4-04.jpg"
              class="services-rectangle23"
              oncontextmenu="return false"
              style="-webkit-touch-callout:none"
            />
          </div>
        </div>
      </div>
      <div class="services-types-aging">
        <div class="services-container12-aging">
          <img
            alt="Rectangle1224"
            src="/external/aging.png"
            class="services-rectangle14-aging"
            oncontextmenu="return false"
              style="-webkit-touch-callout:none"
          />
        </div>
        <span class="services-text16-aging">
          <span class="Content">
            <b>Clients in the VR environment and XR therapists,</b>
            who are psychological counseling experts educated
            on the XR device and contents used in psychology
            counseling online and offline, <b>can interact remotely
            and safe psychological counseling can be carried
            out</b> through the expert’s direct intervention.
            <br/><br/>
            VHEX Lab also provides educational programs to
            train XR therapists who can provide psychological
            counseling using XR.
          </span>
        </span>
      </div>
    </section>
    <section class="services-empathy-chair-vr-section2">
      <h2 class="services-heading7">Who would you like to meet?</h2>
      <div class="services-container18">
        <div class="services-container19">
          <img
            alt="Rectangle1233"
            src="/external/page5-01.png"
            class="services-rectangle24"
            oncontextmenu="return false"
              style="-webkit-touch-callout:none"
          />
          <img
            alt="Rectangle1231"
            src="/external/page5-02.png"
            class="services-rectangle25"
            oncontextmenu="return false"
              style="-webkit-touch-callout:none"
          />
        </div>
        <div class="services-container20">
          <img
            alt="Rectangle1233"
            src="/external/page5-03.png"
            class="services-rectangle26"
            oncontextmenu="return false"
              style="-webkit-touch-callout:none"
          />
        </div>
      </div>
      <span class="services-text45">
        <span>
          Gestalt psychotherapy techniques “Empty Chair” and “Two Chairs” are implemented in a VR environment. XR
          therapists use PC-based software to consult with patients in XR and the patient interacts with a virtual
          avatar that the therapist controls. This technique guarantees the patient feels fully immersed and can
          instantly identify the issue.
        </span>
        <span>Meeting the “subject of conict” to resolve your feelings.</span>
        <br class="services-text50" />
        <br class="Content" />
        <span>
          “Empty Chair” uses aging 3D avatars. Meet your childhood self to provide support and encouragement,
          gain confidence by meeting a positive future version of yourself. Meet the “subject of grief” and heal
          through sufficient mourning. Or meet the object of conflict to resolve your feelings. “Two Chairs” allows
          two ambivalent selves, implemented as 3D avatars, to sit in two chairs, interact and heal their egos through
          counseling. VHEX Lab can provide XR therapists with powerful psychotherapy tools for high quality
          counseling by creating highly accurate avatars using just one picture.
        </span>
      </span>
    </section>
    <div>
      <div class="services-container22">
        <!-- <DangerousHTML
          html="<script>
  document.addEventListener('DOMContentLoaded', function() {
    // get the element with the 'scroll-top' data-role
    const scrollTopBtn = document.querySelector('[data-role='scroll-top']');
  
    // when the element is clicked
    scrollTopBtn.addEventListener('click', function() {
      // smoothly scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    });
  });
  </script>
  "
        ></DangerousHTML> -->
      </div>
    </div>
  </div>
</template>

<script>
import DangerousHTML from 'dangerous-html/vue'

import AppNavbar21 from '../components/navbar21'

export default {
  name: 'Services',
  props: {},
  components: {
    AppNavbar21,
    DangerousHTML,
  },
  data() {
    return {
      rawfj8e: ' ',
      rawi3yn: ' ',
    }
  },
  metaInfo: {
    title: 'Services - VHEX Lab',
    meta: [
      {
        property: 'og:title',
        content: 'Services - VHEX Lab',
      },
    ],
  },
}
</script>

<style scoped>
.release-text{
  color:white;
  font-size:40px;
  width: 1169px;
  align-self: center;
  text-align: left;
}

.release-text-navy{
  color:#0e0f1e;
  font-size:40px;
  
  width: 1169px;
  align-self: center;
  margin-top: 120px;
  text-align: center;
}

.services-container10 {
  width: 100%;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #0e0f1e;
}
.services-header {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.services-fragment1 {
  display: contents;
}
.services-text10 {
  display: inline-block;
}
.services-fragment2 {
  display: contents;
}
.services-text11 {
  color: rgb(255, 255, 255);
  display: inline-block;
}
.services-fragment3 {
  display: contents;
}
.services-text12 {
  color: #ffffff;
  display: inline-block;
}
.services-fragment4 {
  display: contents;
}
.services-text13 {
  display: inline-block;
}
.services-fragment5 {
  display: contents;
}
.services-text14 {
  display: inline-block;
}
.services-empathy-vr-section1 {
  width: 100%;
  height: 1750px;
  display: flex;
  position: relative;
  align-items: center;
  flex-shrink: 1;
  flex-direction: column;
  justify-content: center;
  background-color: #16182d;
  padding-bottom: 100px;
}
.services-container11 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.services-heading1 {
  color: var(--dl-color-main-orange-01);
  width: 1169px;
  align-self: center;
  text-align: left;
}
.services-heading2 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  width: 1169px;
  align-self: center;
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: left;
}
.services-text15 {
  color: rgb(255, 255, 255);
  width: 1100px;
  height: auto;
  align-self: flex-start;
  font-style: Light;
  margin-top: var(--dl-space-space-twounits);
  text-align: left;
  font-stretch: normal;
}
.services-images1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: 636px;
  display: flex;
  padding: 0px;
  margin-top: var(--dl-space-space-threeunits);
  align-items: flex-start;
  justify-content: center;
}
.services-empathyvrcollectexpression1 {
  width: 534px;
  height: 585px;
  align-self: flex-end;
  object-fit: cover;
}
.services-photo1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  height: 248.27996826171875px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
  flex-direction: column;
}
.services-frame61 {
  gap: var(--dl-space-space-oneandhalfunits);
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-shrink: 1;
}
.services-rectangle10 {
  width: 336px;
  height: 285px;
  border-color: var(--dl-color-main-navy-01);
  border-width: 5px;
  border-radius: var(--dl-radius-radius-radius8);
}
.services-rectangle11 {
  width: 336px;
  height: 285px;
  border-color: var(--dl-color-main-logo-red);
  border-width: 5px;
  border-radius: var(--dl-radius-radius-radius8);
}
.services-frame71 {
  gap: var(--dl-space-space-oneandhalfunits);
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-shrink: 1;
}
.services-rectangle12 {
  width: 336px;
  height: 285px;
  border-color: var(--dl-color-main-logo-green);
  border-width: 5px;
  border-radius: var(--dl-radius-radius-radius8);
}
.services-rectangle13 {
  width: 336px;
  height: 285px;
  border-color: var(--dl-color-main-logo-yellow);
  border-width: 5px;
  border-radius: var(--dl-radius-radius-radius8);
}
.services-types {
  gap: var(--dl-space-space-threeunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  align-items: flex-start;
  justify-content: center;
}
.services-types-aging {
  gap: var(--dl-space-space-threeunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  align-items: flex-start;
  justify-content: center;
}
.services-container12 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: 500px;
  display: flex;
  align-items: flex-start;
  margin-left: -100px;
  flex-direction: column;
}
.services-container12-aging {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width:500px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.services-rectangle14-aging {
  width: 480px;
  border-radius: var(--dl-radius-radius-radius8);
}
.services-rectangle14 {
  width: 387px;
  height: 222px;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: var(--dl-radius-radius-radius8);
}
.services-rectangle15 {
  width: 387px;
  height: 222px;
  align-self: flex-end;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.12);
  margin-top: -90px;
  border-radius: var(--dl-radius-radius-radius8);
}
.services-text16 {
  color: rgb(255, 255, 255);
  width: 600px;
  height: auto;
  align-self: flex-start;
  font-style: Light;
  margin-top: 0px;
  text-align: left;
  font-stretch: normal;
}
.services-text16-aging {
  color: #16182d;
  width: 600px;
  height: auto;
  align-self: flex-start;
  font-style: Light;
  margin-top: 0px;
  text-align: left;
  font-stretch: normal;
}
.services-text18 {
  color: #ffffff;
}
.services-text25 {
  color: #ffffff;
}
.services-empathy-vr-section2 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: 1180px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #16182d;
}
.services-heading3 {
  color: var(--dl-color-main-orange-01);
  width: 1169px;
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
  text-align: left;
}
.services-container13 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  height: 906px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.services-container14 {
  flex: 0 0 auto;
  width: 800px;
  height: 838px;
  display: flex;
  align-items: flex-start;
  margin-left: 0px;
  flex-direction: column;
}
.services-rectangle16 {
  width: 751px;
  height: 527px;
  align-self: flex-end;
  border-radius: var(--dl-radius-radius-radius8);
}
.services-rectangle17 {
  width: 753px;
  height: 500px;
  margin-top: -200px;
  margin-left: -120px;
}
.services-container15 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.services-rectangle18 {
  width: 456px;
  height: 247px;
  border-radius: 16px;
}
.services-rectangle19 {
  width: 456px;
  height: 247px;
  border-radius: 16px;
}
.services-container16 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-self: center;
  margin-top: -400px;
  align-items: flex-start;
  margin-right: -430px;
  flex-direction: column;
  justify-content: flex-start;
}
.services-heading4 {
  color: var(--dl-color-main-orange-01) #FFFFFF;
  width: 861px;
  align-self: center;
  text-align: right;
}
.services-text28 {
  color: var(--dl-color-theme-secondary1);
}
.services-text29 {
  color: var(--dl-color-main-orange-01);
}
.services-text30 {
  color: var(--dl-color-theme-secondary1);
}
.services-text31 {
  color: rgb(255, 255, 255);
  width: 850px;
  height: auto;
  align-self: center;
  font-style: Light;
  text-align: right;
  font-stretch: normal;
}
.services-empathy-chair-vr-section1 {
  width: 100%;
  height: 1900px;
  display: flex;
  position: relative;
  align-items: center;
  flex-shrink: 1;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-light);
}
.services-container17 {
  width: 1567px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.services-heading5 {
  color: var(--dl-color-main-orange-01);
  align-self: center;
  /* margin-top: var(--dl-space-space-oneandhalfunits); */
  text-align: left;
}
.services-heading5-1 {
  color: var(--dl-color-main-orange-01);
  align-self: center;
  text-align: left;
  font-size:40px;
}
.services-heading6 {
  fill: var(--dl-color-main-navy-01);
  color: var(--dl-color-main-navy-01);
  width: 1565px;
  align-self: center;
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: center;
}
.services-text34 {
  fill: var(--dl-color-main-navy-01);
  color: var(--dl-color-main-navy-01);
  width: 1100px;
  height: auto;
  align-self: center;
  font-style: Light;
  margin-top: var(--dl-space-space-twounits);
  text-align: left;
  font-stretch: normal;
  justify-content: center;
}
.services-text42 {
  font-size: 20px;
  font-family: "Open Sans";
  font-weight: 700;
  line-height: 1.5;
  text-transform: none;
  text-decoration: none;
}
.services-images2 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: 636px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  margin-top: var(--dl-space-space-threeunits);
  align-items: flex-start;
  justify-content: center;
}
.services-empathyvrcollectexpression2 {
  width: 590px;
  height: 585px;
  object-fit: cover;
}
.services-photo2 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  height: 248.27996826171875px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
  flex-direction: column;
}
.services-frame62 {
  gap: var(--dl-space-space-oneandhalfunits);
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-shrink: 1;
}
.services-rectangle20 {
  width: 336px;
  height: 285px;
  border-color: var(--dl-color-main-navy-01);
  border-width: 5px;
  border-radius: var(--dl-radius-radius-radius8);
}
.services-rectangle21 {
  width: 336px;
  height: 285px;
  border-color: var(--dl-color-main-logo-red);
  border-width: 5px;
  border-radius: var(--dl-radius-radius-radius8);
}
.services-frame72 {
  gap: var(--dl-space-space-oneandhalfunits);
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-shrink: 1;
}
.services-rectangle22 {
  width: 336px;
  height: 285px;
  border-color: var(--dl-color-main-logo-green);
  border-width: 5px;
  border-radius: var(--dl-radius-radius-radius8);
}
.services-rectangle23 {
  width: 336px;
  height: 285px;
  border-color: var(--dl-color-main-logo-yellow);
  border-width: 5px;
  border-radius: var(--dl-radius-radius-radius8);
}
.services-empathy-chair-vr-section2 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: 1371px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-theme-neutral-light);
  padding-top: 50px;
}
.services-heading7 {
  color: var(--dl-color-main-navy-01);
  width: 1169px;
  font-size: 80px;
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
  text-align: left;
  font-family: "Open Sans";
  font-weight: 700;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.services-container18 {
  gap: var(--dl-space-space-twounits);
  width: 1204px;
  height: 771px;
  display: flex;
  align-self: center;
  align-items: center;
  border-radius: 24px;
  justify-content: center;
  background-color: #16182d;
}
.services-container19 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.services-rectangle24 {
  width: 500px;
  border-radius: 16px;
}
.services-rectangle25 {
  width: 500px;
  border-radius: 16px;
}
.services-container20 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.services-rectangle26 {
  width: 530px;
  border-radius: 16px;
}
.services-rectangle27 {
  width: 456px;
  height: 247px;
  border-radius: 16px;
}
.services-text45 {
  fill: var(--dl-color-main-navy-01);
  color: var(--dl-color-main-navy-01);
  width: 1100px;
  height: auto;
  align-self: center;
  font-style: Light;
  margin-top: 0px;
  margin-bottom:80px;
  text-align: left;
  font-stretch: normal;
}
.services-text50 {
  color: var(--dl-color-main-navy-01);
}
.services-container22 {
  display: contents;
}
</style>
